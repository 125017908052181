import React from "react";
import { graphql } from "gatsby";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import HeroCard from "../../components/HeroCard";

const Contact = (props) => {
  const indexData = props.data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <Layout bodyClass="page-contact">
      <SEO
        title={indexData.title}
        description={indexData.SEODescription}
        path={indexData.path}
      />
      <HeroCard
        image={indexData.headerImage}
        title={indexData.headerTitle}
        subtitle={indexData.headerSubtitle}
        size="small"
      />
      <Container className="content-container">
        <div className="content">
          <div className="phone-details">
            <h4>{indexData.urgentTitle}</h4>
            <p>{indexData.urgentSubtitle}</p>
            <p className="phone-number">Call us at &nbsp;<a>{indexData.urgentPhoneNumber}</a></p>
            <Card>
              <Card.Body>
                <Card.Title>{indexData.timingsTitle}</Card.Title>
                <div className="timings">
                  {indexData.timings.map((timing) => (
                    <div className="single-timing">
                      <Card.Text>{timing.duration}</Card.Text>
                      <Card.Text>{timing.days}</Card.Text>
                    </div>
                  ))}
                </div>
              </Card.Body>
            </Card>
          </div>
          <h4>{indexData.addressTitle}</h4>
          <div className="address-container">
            {indexData.addresses.map((addressEntry) => (
              <Card key={addressEntry.title}>
                <Card.Body>
                  <Card.Title>{addressEntry.title}</Card.Title>
                  <Card.Text>{addressEntry.address}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ContactQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/contact.md/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            SEODescription
            headerImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            headerTitle
            headerSubtitle
            addressTitle
            addresses {
              title
              address
            }
            timingsTitle
            timings {
              duration
              days
            }
            urgentTitle
            urgentSubtitle
            urgentPhoneNumber
          }
          html
        }
      }
    }
  }
`;

export default Contact;
